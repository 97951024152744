//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex'
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { getError } from "~/utils/vuelidate";

export default {
  name: 'BlokHomeHero',
  mixins: [validationMixin],
  props: {
    blok: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isVisibleAos: false,
      byNumber: {
        number: '',
      },
    }
  },
  validations: {
    byNumber: {
      number: {
        required,
        isValid(value) {
          return value?.replace(/\s/g, '')?.length === 8;
        },
      },
    },
  },
  computed: {
    aosAnimate() {
      return this.isVisibleAos ? 'aos-animate' : null;
    },
    heading() {
      switch(this.index) {
        case 0:
          return 'h1';
        default:
          return 'h2';
      }
    },
    lazyStrategy() {
      if (this.index === 0) {
        return {
          'data-not-lazy': '',
        };
      }

      return null;
    },
    isApps() {
      return this.blok.apps?.length;
    },
    isQrCode() {
      return this.blok.qr_code?.filename;
    },
    errors() {
      return {
        byNumber: {
          number: getError(this.$v.byNumber.number, {
            required: this.$t('field-required'),
            isValid: this.$t('invalid-value'),
          }),
        },
      }
    },
  },
  methods: {
    ...mapMutations('hero', [
      'setNumber'
    ]),
    aosVisibilityChanged(isVisible) {
      this.isVisibleAos = isVisible;
    },
    handleInput(form, input) {
      this.$v[form][input].$reset();
    },
    handleSubmit() {
      this.$v.byNumber.$touch();
      if (this.$v.byNumber.$invalid) return;

      this.setNumber(this.byNumber.number);
      this.scrollTo(this.blok.form_scroll_to_id);
    },
    scrollTo(anchor) {
      const anchorEl = anchor && document.getElementById(anchor);

      if (anchorEl) {
        const header = document.getElementById('header');
        const offset = header ? -header.offsetHeight : 0;
        const duration = 500;
        const options = {
          offset,
          lazy: false,
          cancelable: false,
        };

        this.$scrollTo(anchorEl, duration, options);
      }
    },
  },
}
