//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BaseChatItem',
  props: {
    img: {
      type: String,
      default: null,
    },
    imgAlt: {
      type: String,
      default: null,
    },
    message: {
      type: Object,
      default: null,
    },
  },
}
